// Keenthemes' plugins
window.KTUtil = require('./js/components/util.js');
window.KTBlockUI = require('./js/components/blockui.js');
window.KTEventHandler = require('./js/components/event-handler.js');
window.KTMenu = require('./js/components/menu.js');
window.KTScroll = require('./js/components/scroll.js');
window.KTScrolltop = require('./js/components/scrolltop.js');
window.KTToggle = require('./js/components/toggle.js');
window.KTDrawer = require('./js/components/drawer.js');

//window.KTCookie = require('./js/components/cookie.js');
//window.KTDialer = require('./js/components/dialer.js');
//window.KTFeedback = require('./js/components/feedback.js');
//window.KTImageInput = require('./js/components/image-input.js');
/*window.KTPasswordMeter = require('./js/components/password-meter.js');*/
//window.KTSearch = require('./js/components/search.js');
//window.KTStepper = require('./js/components/stepper.js');
//window.KTSwapper = require('./js/components/swapper.js');

// Layout base js
window.KTApp = require('./js/layout/app.js');
window.KTLayoutAside = require('./js/layout/aside.js');
window.KTLayoutExplore = require('./js/layout/explore.js');
window.KTLayoutSearch = require('./js/layout/search.js');
window.KTLayoutToolbar = require('./js/layout/toolbar.js');
